export const ENTITY_LEGAL_FILE = {
    RESPONSIBLE_CPF: 'RESPONSIBLE_CPF',
    ID_CARD: 'ID_CARD',
    ELECTION_RECORD: 'ELECTION_RECORD',
    PROCURATION: 'PROCURATION',
    SOCIAL_STATUS: 'SOCIAL_STATUS',
    INTERNAL_RULES: 'INTERNAL_RULES',
    PUBLIC_DEED: 'PUBLIC_DEED',
    DEBIT_CERTIFICATE: 'DEBIT_CERTIFICATE',
    FGTS: 'FGTS',
    ACCOUNTING: 'ACCOUNTING',
    AUDIT_OPINION: 'AUDIT_OPINION',
    PARTNERSHIP_TERM: 'PARTNERSHIP_TERM',
    ACTIVITY_REPORT: 'ACTIVITY_REPORT',
    NOMINAL_RELATION: 'NOMINAL_RELATION',
    NOMINAL_RELATION_TYPE_ONE: 'NOMINAL_RELATION_TYPE_ONE',
    NOMINAL_RELATION_TYPE_TWO: 'NOMINAL_RELATION_TYPE_TWO',
    PROFILE_ANALYSIS: 'PROFILE_ANALYSIS',
    CARE_PLAN: 'CARE_PLAN',
    GOVERNING_BODY: 'GOVERNING_BODY',
    MONITORING_REPORT: 'MONITORING_REPORT',
    REQUIREMENTS_DECLARATION: 'REQUIREMENTS_DECLARATION',
    ART_5_DECLARATION: 'ART_5_DECLARATION',
    ACCREDITATION_ACT: 'ACCREDITATION_ACT',
    BENEFITS_TYPE_ONE: 'BENEFITS_TYPE_ONE',
    BENEFITS_TYPE_TWO: 'BENEFITS_TYPE_TWO',
    PUBLIC_SCHOLARSHIP_PROJECTS: 'PUBLIC_SCHOLARSHIP_PROJECTS',
    ANNOUNCEMENT: 'ANNOUNCEMENT',
    CEBAS: 'CEBAS',
    MONTHLY_REPORT: 'MONTHLY_REPORT',
    INFORMATION_REQUEST: `INFORMATION_REQUEST`,
    CHARITABLE_CERTIFICATE: `CHARITABLE_CERTIFICATE`
}