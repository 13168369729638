export const ENTITY_GROUP_TYPE = {
    INSTITUTION_NAME: 'INSTITUTION_NAME',
    CNPJ: 'CNPJ',
    ANNOUNCEMENT: 'ANNOUNCEMENT',
    DISCLOSURE_PROOF: 'DISCLOSURE_PROOF',
    SOCIAL_ASSISTANT_OPINION: 'SOCIAL_ASSISTANT_OPINION',
    FINAL_RESULT: 'FINAL_RESULT',
    CEBAS_CERTIFICATE: 'CEBAS_CERTIFICATE',
    CEBAS_EXTENSION: 'CEBAS_EXTENSION',
    CEBAS_EDUCATION: 'CEBAS_EDUCATION',
    CEBAS_NOTES_COPY: 'CEBAS_NOTES_COPY',
    INFORMATION_REQUEST_CERTIFICATE: 'INFORMATION_REQUEST_CERTIFICATE',
    INFORMATION_REQUEST_ANSWER: 'INFORMATION_REQUEST_ANSWER'
}

export const ENTITY_GROUP_TYPE_MAPPER = {
    INSTITUTION_NAME: 'Autorização de funcionamento',
    CNPJ: 'Cartão do CNPJ',
    ANNOUNCEMENT: 'Edital',
    DISCLOSURE_PROOF: 'Comprovante(s) de divulgação',
    SOCIAL_ASSISTANT_OPINION: 'Pareceres da assistente social',
    FINAL_RESULT: 'Extrato final',
    CEBAS_CERTIFICATE: 'Certificação concedida',
    CEBAS_EXTENSION: 'Prorrogação do prazo',
    CEBAS_EDUCATION: 'Certidão CEBAS-Educação',
    CEBAS_NOTES_COPY: 'Cópia das notas técnicas',
    INFORMATION_REQUEST_CERTIFICATE: 'Certificação',
    INFORMATION_REQUEST_ANSWER: 'Resposta'
}